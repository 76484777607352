const zh = {
  'video-path':'/videos/video-zh.mp4',
  'poster-path':'/video-zh.jpg',
  'lan':'中文',
  'app.learn':'学习 {name}',
  'nav.welcome':'欢迎',
  'nav.video':'视频介绍',
  'nav.feature':'产品特点',
  'nav.aboutUs':'关于我们',
  'welcome.title':'联合维度',
  'welcome.subtitle':'居家康测系统',
  'video.title':'联合维度引领科技潮流',
  'video.subtitle':'看不见你的面容，却能隔空感知你的心跳',
  'feature.title1':'功能概述',
  'feature.c1.title':'安全看护',
  'feature.c1.content':'对老人日常起居姿态和生命体征进行智能判断，实时反映老人日常活动/生命体征规律和提供安全风险警告。'+
  '老人如果出现例如摔倒、久卧不起、呼吸心率异常等紧急状况，系统将立即自动通知指定联系人核实、确认及救助。',
  'feature.c2.title':'健康趋势追踪',
  'feature.c2.content':'系统每日将老人生活状态（活动时间、坐下时间、睡觉时间、出门时间等）、生命体征（呼吸率范围、心率规律）'+
  '情况以简报形式定期推送到子女手机APP端，帮助子女定时了解老人在家中的日常生活和健康状况。',
  'feature.c3.title':'与社区联动',
  'feature.c3.content':'社区、日间照料中心与系统联动后，可协助子女更多关注老人安全、健康状况，紧急情况下及时确认与救助。'+
  '建设家居‘虚拟养老院’，为老人安全、健康护航，为老人子女分忧。',
  'feature.title2':'产品特性及优势',
  'feature.subtitle2':'我们将保护用户隐私视为首要任务和责任，并作技术上和法律上的承诺：如有触犯或泄露用户隐私，本公司愿意承担任何法律责任和经济上的赔偿责任。',
  'feature.c4.title':'隐形看护',
  'feature.c4.content':'系统采用人工智能姿态识别及远程生理指标滤波技术，凭借安装在家居客厅，卧室等空间上方的设备，'+
  '采用远端非接触式探测、计算、分析老人姿态、生理指标及位置/时间等信息，对人体无任何辐射，也无穿戴、按键等使用负担。',
  'feature.c5.title':'保护隐私',
  'feature.c5.content':'系统运用边缘计算技术和人工智能深度学习算法实现由前端设备完成对信息的计算、分析、判断等任务，'+
  '只传送计算结果。不需要存储、传输影像至后端服务器，这样就可以确保用户的隐私安全。',
  'feature.c6.title':'无需维护',
  'feature.c6.content':'设备接通电源、WIFI后实现自行运转、升级、检测等，不需使用者做任何生活方式的改变和配合，不影响日常生活。整个系统的耗电量也非常低，还不及一间客厅的照明用电量，因此完全不用担心持续的大额开销',
  'feature.c7.title':'全天看护',
  'feature.c7.content':'系统完全自主运行，并能适应白天黑夜不同的光照环境，可实现7X24小时不间断看护。',
  'feature.c8.title':'全屋覆盖',
  'feature.c8.content':'系统非穿戴、无按钮、无压力传感器，使看护范围由局部看护扩展至全方位覆盖看护。',
  'feature.c9.title':'安全可靠',
  'feature.c9.content':'该系统完全智能、自动化运行，不给老人增添任何负担，不需老人对设备做任何照顾，实现居家室内隐形看护。',
  'aboutUs.title':'联合维度',
  'aboutUs.content':'联合维度（广州）科技有限公司是一家从事计算机智能系统的研发、生产、推广和服务的高科技企业。'+
  '公司致力于开发和应用人工智能、计算机视觉、计算机软件系统和相关硬件设备，使之服务于中国市场的居家养老和智能健康家居等领域。'+
  '目前公司的产品系列包括独居人士和空巢老人的安全健康看护系统和安康家园服务系统。公司将进一步拓展相关的智能家居系列产品，让智能技术成果造福社会。',
  'contact.title':'联系我们',
  'contact.phone':'电话：020-83624193',
  'contact.email':'邮箱：info@utdimensions.com',
  'contact.address':'地址：广州市天河区天河北路908号高科大厦B座706室'
};
export default zh;
