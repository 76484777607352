import React from 'react';
import {FormattedMessage} from 'react-intl';
import MeetingLogo  from '../resources/images/meeting.jpg';
import QrCodeLogo from '../resources/images/qrcodeblack.jpg';

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page k49ai3cohad-editor_css' },
  logo: {
    className: 'header2-logo k49ajezvkjr-editor_css',
    children: 'http://utdimensions.com/LOGO256-1.d88fd1a0.png',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav~k49bnzfms99',
        to: 'Content1_0',
        children: '关于我们',
        className: 'menu-item k49anbmd5lk-editor_css',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 k49b4m8181i-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title k49afnhs80k-editor_css',
    children: '/product.png',
  },
  content: {
    className: 'banner0-content k49lbyguq7j-editor_css',
    children: (
      <>
        <p><FormattedMessage id ='welcome.title'/></p>
        <p style = {{fontSize:'16px'}}><FormattedMessage id ='welcome.subtitle'/></p>
      </>
    ),
  },
  button: {
    className: 'banner0-button',
    children: (
      <>
        <p>用户协议</p>
      </>
    ),
    href: 'http://utdimensions.com/agreement.html',
    target: '_blank',
  },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p><FormattedMessage id='video.title'/></p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: (
          <>
            <p><FormattedMessage id='video.subtitle'/></p>
          </>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video: <FormattedMessage id='video-path'/>,//  need refactor
      image: <FormattedMessage id='poster-path'/>,//  need refactor
    },
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p><FormattedMessage id='feature.title1'/></p>
          </>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p><FormattedMessage id='feature.c1.title'/></p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p><FormattedMessage id='feature.c1.content'/></p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p><FormattedMessage id='feature.c2.title'/></p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p><FormattedMessage id='feature.c2.content'/></p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p><FormattedMessage id='feature.c3.title'/></p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>
                    <FormattedMessage id='feature.c3.content'/>
                  </p>
                </>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p><FormattedMessage id='feature.title2'/></p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <>
            <p><FormattedMessage id='feature.subtitle2'/></p>
          </>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p><FormattedMessage id='feature.c4.title'/></p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  <FormattedMessage id='feature.c4.content'/>
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p><FormattedMessage id='feature.c5.title'/></p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  <FormattedMessage id='feature.c5.content'/>
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p><FormattedMessage id='feature.c6.title'/></p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p><FormattedMessage id='feature.c6.content'/></p>
              </>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p><FormattedMessage id='feature.c7.title'/></p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p><FormattedMessage id='feature.c7.content'/></p>
              </>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p><FormattedMessage id='feature.c8.title'/></p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p><FormattedMessage id='feature.c8.content'/></p>
              </>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p><FormattedMessage id='feature.c9.title'/></p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p><FormattedMessage id='feature.c9.content'/></p>
              </>
            ),
          },
        },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper k49kyvm2cgd-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: {
    className: 'content1-img k49kxx769kk-editor_css',
    md: 10,
    xs: 24,
  },
  img: {
    children: MeetingLogo,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <>
        <p>
          <b><FormattedMessage id='aboutUs.title'/></b>
        </p>
      </>
    ),
  },
  content: {
    className: 'content1-content k49ky6r13xg-editor_css',
    children: (
      <>
        <p>
          <FormattedMessage id='aboutUs.content'/>
        </p>
      </>
    ),
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper k49l6um50gs-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <>
            <p><FormattedMessage id='contact.title'/></p>
            <img src={QrCodeLogo} height='150' alt="Qrcode"/>
          </>
        ),
        className: 'title-h1 k49l7dzrufe-editor_css',
      },
      {
        name: 'content',
        children: (
          <>
            <p><FormattedMessage id='contact.phone'/></p>
          </>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <>
            <p><FormattedMessage id='contact.email'/></p>
          </>
        ),
        className: 'title-content',
      },
      {
        name: 'content~k49l8yt9hyq',
        className: 'k49l97s4a0p-editor_css',
        children: (
          <>
            <p><FormattedMessage id='contact.address'/></p>
          </>
        ),
      },
    ],
  },
};
export const Content130DataSource_WithoutPhoneAddress = {
    OverPack: {
      className: 'home-page-wrapper content13-wrapper k49l6um50gs-editor_css',
      playScale: 0.3,
    },
    titleWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'image',
          children:
            'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
          className: 'title-image',
        },
        {
          name: 'title',
          children: (
            <>
              <p><FormattedMessage id='contact.title'/></p>
              <img src={QrCodeLogo} height='150' alt="Qrcode"/>
            </>
          ),
          className: 'title-h1 k49l7dzrufe-editor_css',
        },
        {
          name: 'content2',
          children: (
            <>
              <p><FormattedMessage id='contact.email'/></p>
            </>
          ),
          className: 'title-content',
        },
      ],
    },
  };
  
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright k49kqo62wdn-editor_css',
    children: (
      <>
        <a href='http://www.beian.miit.gov.cn' target='_blank'>粤ICP备19052185号</a>
        <p>
          ©2020 <a href="https://utdimensions.com">United Dimensions</a> All Rights
          Reserved
        </p>

      </>
    ),
  },
};
