/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { injectIntl}from 'react-intl';

import {Tabs, Button,Layout,Icon } from 'antd';

import Nav2 from './Nav2';
import Banner0 from './Banner0';
import Banner1 from './Banner1';
import Content4 from './Content4';
import Content0 from './Content0';
import Content3 from './Content3';
import Feature1 from './Feature1';
import Content13 from './Content13';
import Footer0 from './Footer0';

import {
  Nav20DataSource,
  Banner00DataSource,
  Banner10DataSource,
  Content40DataSource,
  Content00DataSource,
  Content30DataSource,
  Feature10DataSource,
  Content130DataSource,
  Footer00DataSource,
  Content130DataSource_WithoutPhoneAddress
} from './data.source';
import './less/antMotionStyle.less';
const {TabPane} = Tabs;
const {Header,Footer,Content} = Layout;
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1600353_twonx7z90dl.js',
});
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const {intl} = this.props;
    const lan = this.props.locale;
    const f = intl.formatMessage;
    const operations = (
      <Button onClick={()=>this.props.setLocale(lan=='en-US'?'zh-CN':'en-US')}>
      {lan=='en-US'?'中文':'English'}
      </Button>
    )

    const children = lan ==='en-US' ? <div>
    <Tabs size='large' tabBarGutter={0} tabBarStyle={{padding:'0 2vw 0 2vw'}} animated={false} tabBarExtraContent={operations}>
      <TabPane tab = {f({id:'nav.welcome'})} key="1">
        <div className='one-page-content'>
          <Banner0
            id="Banner0_0"
            key="Banner0_0"
            dataSource={Banner00DataSource}
            isMobile={this.state.isMobile}
          />
        </div>
      </TabPane>
      <TabPane tab = {f({id:'nav.video'})} key="2">
        <Content4
          id="Content4_0"
          key="Content4_0"
          dataSource={Content40DataSource}
          isMobile={this.state.isMobile}
        />
      </TabPane>
      <TabPane tab = {f({id:'nav.feature'})} key="3">
        <Content0
          id="Content0_0"
          key="Content0_0"
          dataSource={Content00DataSource}
          isMobile={this.state.isMobile}
        />
        <Content3
          id="Content3_0"
          key="Content3_0"
          dataSource={Content30DataSource}
          isMobile={this.state.isMobile}
        />
      </TabPane>
      <TabPane tab = {f({id:'nav.aboutUs'})} key="4">
        <Feature1
          id="Feature1_0"
          key="Feature1_0"
          dataSource={Feature10DataSource}
          isMobile={this.state.isMobile}
        />
        <Content13
          id="Content13_0"
          key="Content13_0"
          dataSource={Content130DataSource_WithoutPhoneAddress}
          isMobile={this.state.isMobile}
        />
      </TabPane>
    </Tabs>

    <div className='one-page-footer'>
    <Footer0
      id="Footer0_0"
      iconfont={IconFont}
      key="Footer0_0"
      dataSource={Footer00DataSource}
      isMobile={this.state.isMobile}
    />
    </div>
    </div>
    : <div>
      <Tabs size='large' tabBarGutter={0} tabBarStyle={{padding:'0 2vw 0 2vw'}} animated={false} tabBarExtraContent={operations}>
        <TabPane tab = {f({id:'nav.welcome'})} key="1">
          <div className='one-page-content'>
            <Banner0
              id="Banner0_0"
              key="Banner0_0"
              dataSource={Banner00DataSource}
              isMobile={this.state.isMobile}
            />
          </div>
        </TabPane>
        <TabPane tab = {f({id:'nav.video'})} key="2">
          <Content4
            id="Content4_0"
            key="Content4_0"
            dataSource={Content40DataSource}
            isMobile={this.state.isMobile}
          />
        </TabPane>
        <TabPane tab = {f({id:'nav.feature'})} key="3">
          <Content0
            id="Content0_0"
            key="Content0_0"
            dataSource={Content00DataSource}
            isMobile={this.state.isMobile}
          />
          <Content3
            id="Content3_0"
            key="Content3_0"
            dataSource={Content30DataSource}
            isMobile={this.state.isMobile}
          />
        </TabPane>
        <TabPane tab = {f({id:'nav.aboutUs'})} key="4">
          <Feature1
            id="Feature1_0"
            key="Feature1_0"
            dataSource={Feature10DataSource}
            isMobile={this.state.isMobile}
          />
          <Content13
            id="Content13_0"
            key="Content13_0"
            dataSource={Content130DataSource}
            isMobile={this.state.isMobile}
          />
        </TabPane>
      </Tabs>

      <div className='one-page-footer'>
      <Footer0
        id="Footer0_0"
        iconfont={IconFont}
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />
      </div>
      </div>
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}

export default injectIntl(Home);
