import React, { Component } from 'react';
import { Button } from 'antd';
import './App.css';
import {FormattedMessage} from 'react-intl';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Button type="primary" onClick={()=>this.props.setLocale('en-US')}>English</Button>
        <Button type="primary" onClick={()=>this.props.setLocale('zh-CN')}>中文</Button>
        <FormattedMessage id="app.learn" defaultMessage='Hello, {name}' values={{ name: 'React' }} />
      </div>
    );
  }
}

export default App;
