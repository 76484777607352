import React,{useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Home from './Home/index';
import * as serviceWorker from './serviceWorker';
import {IntlProvider} from 'react-intl';
import zh from './translations/zh';
import en from './translations/en';

global.DOMParser = new (require('jsdom').JSDOM)().window.DOMParser;

const Root = ()=>{
  const [locale,setLocale] = useState(navigator.language);
  let messages;
  switch(locale){
    case 'zh-CN':messages=zh;break;
    case 'en-US':messages=en;break;
  }

  return(
    <IntlProvider locale={locale} key={locale} messages={messages} defaultLocale='en'>
      <Home locale={locale} setLocale={setLocale} />
    </IntlProvider>
)
}

ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
