const en = {
  'video-path':'/videos/video-en.mp4',
  'poster-path':'/video-en.jpg',
  'lan':'English',
  'app.learn':'Learn {name}',
  'nav.welcome':'Welcome',
  'nav.video':'Video Intro',
  'nav.feature':'Product Features',
  'nav.aboutUs':'About Us',
  'welcome.title':'Omni-Sensing',
  'welcome.subtitle':'Home Safety & Health Care System',
  'video.title':'United Dimensions Leads The Technology Trend',
  'video.subtitle':'I can\'t see your face, but I can sense your heartbeat through the air',
  'feature.title1':'Function Overview',
  'feature.c1.title':'Safeguarding',
  'feature.c1.content':'The Omni-sensing care system intelligently judges the daily living posture and vital signs of the elderly, detects and reports the daily activities / vital signs of the elderly in real time, and provides safety risk warnings.'+
  'If emergency situations such as a fall, lying for too long, irregular respiration or heartbeats occurs, the system will automatically send out an alert immediately to notify the designated contact to verify, confirm and rescue.',
  'feature.c2.title':'Health Trend Tracking',
  'feature.c2.content':'The system regularly pushes the living status of the elderly (activity time, sitting time, sleeping time, time to go out, etc.) and vital signs (breath rate and heart rate ranges) to related family member mobile APP to help family members understand the seniors\' daily activity patterns and health situation at home.',
  'feature.c3.title':'Link with the community',
  'feature.c3.content':'The linkage between the community, caregiver centers and the system can help family members pay more attention to the safety and health of the seniors, and promptly confirm and assist in emergency situations. This linkage functions as a \'virtual nursing home\' to protect the safety and health of the seniors.',
  'feature.title2':'Product Features & Advantages',
  'feature.subtitle2':'We regard the protection of user privacy as our top priority and responsibility, and we make technical and legal commitments to protect user\'s privacy.',
  'feature.c4.title':'Invisible Healthcare',
  'feature.c4.content':'The system utilizes artificial intelligence posture recognition and remote vital sign filtering technology. With the equipment installed above the living room, bedroom, and other living spaces, it remotely detects, recognize, and analyzes seniors\' postures, vital signs and related location / time information and sends out results. Users have no exposure to radiation, no burden to use buttons and to wear any devices.',
  'feature.c5.title':'Privacy Protection',
  'feature.c5.content':'The system utilizes edge computing technology and AI deep learning algorithms to do the recognition, calculation, analysis, and judgment of image information by the devices, and the devices only transmit the calculation results to cloud server and the APP. There is no need to store and transfer images to the back-end server, hence the whole system can ensure the privacy of users.',
  'feature.c6.title':'Minimized Maintenance',
  'feature.c6.content':'After a device is connected to the power supply and WIFI, it is up and running. And it can upgrade its firmware automatically when necessary. Users will not sense any interruption to their daily lives by the devices. The electricity bill for the entire system won\'t be more expensive than that for indoor lighting of your living room.',
  'feature.c7.title':'All Day Healthcare',
  'feature.c7.content':'The system runs completely autonomously and can achieve 7x24 hours of uninterrupted care.',
  'feature.c8.title':'Whole House Coverage',
  'feature.c8.content':'The system is non-wearable, no buttons, no pressure sensor, which extends the range of care from local spots care to full coverage care.',
  'feature.c9.title':'Safe & Reliable',
  'feature.c9.content':'The system runs automatically, and does not add any effort to users. Users do not need do anything to maintain the devices.',
  'aboutUs.title':'United Dimensions',
  'aboutUs.content':'United Dimensions Technologies Co., Ltd. is a high-tech company engaged in the research, development, production, promotion and service of computer artificial intelligent systems. The company is committed to the development and application of artificial intelligence, computer vision, computer software systems and related hardware equipment to serve the elderly care market in China as well as in foreign markets. The company\'s current product line includes safety and health care monitoring system for seniors and empty nesters. The company will further expand the related smart home series product lines, and continuously utilize the AI technology to benefit our society.',
  'contact.title':'Contact Us',
  'contact.phone':'Phone：+86-20-83624193',
  'contact.email':'E-mail：info@utdimensions.com',
  'contact.address':'Address：Rm706 – 908 Tianhe Road North, Tianhe District, Guangzhou, Guangdong, China'
};
export default en;
